<template>
	<div v-loading="loading">
		<el-breadcrumb separator=">" class="path">
			<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
				首页
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/supply_demand' }">供求中心</el-breadcrumb-item>
			<el-breadcrumb-item  class="path-help">供求详情</el-breadcrumb-item>
			<el-breadcrumb-item class="path-help">{{detail.corporate_name}}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="detail">
			<div class="content">
				<div class="cargo">
					<div class="title">
						<div class="name">供求信息</div>
					</div>
					<div class="cargo_info">
						<div class="name item">
							<div class="tit" >货物名称：</div>
							<span :class="detail.sku_id > 0 ? 'href-style' : ''" @click="goDetail()">{{detail.goods_name}}</span>
						</div>
						<div class="demand">
							<div class="time item">
								<div class="tit">发布时间：</div>{{$util.timeStampTurnTime(detail.create_time).split(' ')[0]}}
							</div>
							<div class="number item">
								<div class="tit">求购量：</div>{{detail.num}}{{detail.unit}}
							</div>
							<div class="contact item">
								<div class="tit">联系人：</div>{{detail.name}}
							</div>
						</div>
						<div class="phone item">
							<div class="tit">联系电话：</div>{{detail.phone}}
						</div>
						<div class="end_time item">
							<div class="tit">截止时间：</div>{{$util.timeStampTurnTime(detail.deadline_time).split(' ')[0]}}
						</div>
						<div class="end_time item" v-if="detail.atlas">
							<div class="tit tit2">图片信息：</div>
							<div class="image-list">
								<div class="image-item" v-for="(item,index) in detail.atlas.split(',')">
									<img  :src="item" alt />
								</div>
							</div>
						</div>
						<div class="end_time item">
							<div class="tit tit3">备注：</div>
							{{detail.describe}}
						</div>
					</div>
					<!-- <div class="back">
						<div class="btn" @click="$router.go(-1)">返回</div>
					</div> -->
				</div>
				<div class="company_info">
					<div class="company_top" >
						<img class="logo" :src="$img(detail.logo)" alt="">
						<div class="name">{{detail.corporate_name}}</div>
					</div>
					<div class="info">
						<div class="item"><div class="left">成立时间：</div>{{ $util.timeStampTurnTime(detail.open_time).split(' ')[0]}}</div>
						<div class="item"><div class="left">经营范围：</div>{{detail.scope}}</div>
						<div class="item"><div class="left">公司地址：</div>{{detail.full_address}}{{detail.address}}</div>
					</div>
					<div class="contact_company">公司电话：{{detail.telephone}}</div>
					<!-- <div class="contact_kefu">
						<div class="btn">联系客服</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { SupplyDemandInfo } from '@/api/index.js'
	export default {
		data() {
			return {
				id: '',
				detail:{},
				loading:true,
			}
		},
		created() {
			this.id = this.$route.params.pathMatch;
			this.getSupplyDemandInfo();
		},
		methods: {
			goDetail(){
				this.$router.pushToTab({ path: '/sku-' + this.detail.sku_id })
			},
			getSupplyDemandInfo() {
				SupplyDemandInfo({
					id:this.id
				})
				.then(res=>{
					if(res.code>=0){
						this.detail = res.data;
						this.loading = false;
					}
				})
			}
		},
	}
</script>

<style lang="scss">
	.el-main{
		width: 100% !important;
	}
	body{
		background-color: #F8F8F8 !important;
	}
	.path{
		width: 1200px;
		margin: 0 auto;
		padding: 17px 0;
		.el-breadcrumb__item{
			display: flex;
			align-items: center;
			.el-breadcrumb__separator{
				color: #666;
				font-weight: normal;
				font-size: 14px;
			}
			.el-breadcrumb__inner{
				color: #666 !important;
				font-size: 14px !important;
				line-height: 20px !important;
				&.is-link{
					font-weight: normal !important;
					color: #666 !important;
					font-size: 14px !important;
					line-height: 20px !important;
				}
			}
			
		}
	}
</style>
<style lang="scss" scoped>
	.detail{
		padding-top: 20px;
		background-color: #fff;
		padding-bottom: 60px;
		margin-bottom: 20px;
		.content{
			width: 1200px;
			margin: 0 auto;
			display: flex;
			align-items: flex-start;
			.cargo{
				flex:1;
				border:1px solid #E6E6E6;
				padding-bottom: 32px;
				.title{
					margin: 32px 35px 0 25px;
					border-bottom: 1px solid #E6E6E6;
					display: flex;
					.name{
						color: #222;
						font-size: 20px;
						line-height: 28px;
						padding: 0 10px 6px;
						border-bottom: 2px solid #0DAD94;
					}
				}
				.back{
					display: flex;
					align-items: center;
					justify-content: center;
					.btn{
						color: #fff;
						font-size: 22px;
						line-height: 30px;
						padding: 11px 67px;
						border-radius: 0;
						background-color: #00A98F;
						cursor: pointer;
					}
					
				}
				.cargo_info{
					padding: 29px 24px 0;
					.name,.demand,.phone,.end_time,.desc{
						margin-bottom: 34px;
					}
					.demand{
						display: flex;
						align-items: center;
						.time,.number{
							margin-right: 150px;
							font-family:auto;
						}
					}
					.desc{
						color: #666 !important;
					}
					.item{
						display: flex;
						align-items: center;
						color:#444;
						font-size: 15px;
						line-height: 20px;
						.tit{
							color: #888;
							font-size: 15px;
							line-height: 20px;
						}
					}
				}
			}
			.company_info{
				border: 1px solid #E6E6E6;
				margin-left: 18px;
				width: 242px;
				padding: 24px 18px;
				.company_top{
					display: flex;
					align-items: center;
					flex-direction: column;
					padding-bottom: 16px;
					border-bottom: 1px dashed #E9E9E9;
					.logo{
						width: 64px;
						height: 64px;
						border-radius: 50%;
						display: block;
						margin-bottom: 21px;
					}
					.name{
						color: #222;
						font-size: 14px;
						line-height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
				.info{
					padding-top: 23px;
					padding-bottom: 20px;
					border-bottom: 1px dashed #E9E9E9;
					.item{
						color: #555;
						font-size: 12px;
						line-height: 17px;
						margin-bottom: 14px;
						display: flex;
						align-items: flex-start;
						font-family: auto;
						.left{
							color: #555;
							font-size: 12px;
							line-height: 17px;
							white-space: nowrap;
						}
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
				.contact_company{
					padding: 19px 0 0;
					color: #555;
					font-size: 12px;
					line-height: 17px;
					// border-bottom: 1px dashed #E9E9E9;
				}
				.contact_kefu{
					margin-top: 23px;
					display: flex;
					align-items: center;
					justify-content: center;
					.btn{
						color: #fff;
						font-size: 14px;
						line-height: 20px;
						padding: 6px 24px;
						background-color: #00A98F;
						border-radius: 4px;
					}
				}
			}
		}
	}
	.image-list{
		display: flex;
		flex-wrap: wrap;
		flex:1;
		.image-item{
			width: calc(100% / 5);
			margin-bottom: 15px;
			img{
				width: 130px;
				height: 130px;
				border-radius: 10px;
			}
		}
		
	}
	.tit2{
		width: 130px;
	}
	.tit3{
		width: 75px;
	}
	.href-style{
		cursor: pointer;
		color: #0DAD94 !important;
	}
</style>